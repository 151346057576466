import { forwardRef, ReactNode } from 'react';

import { Link } from 'common/components/Link';
import { Locale } from 'common/i18n/i18n.config';

interface Props {
  children: ReactNode;
  href: string;
  lang: Locale;
}

export const LinkBehavior = forwardRef<HTMLAnchorElement, Props>(({ children, ...props }: Props, ref) => (
  <Link ref={ref} {...props}>
    {children}
  </Link>
));

LinkBehavior.displayName = 'LinkBehavior';
