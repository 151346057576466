export const i18n = {
  defaultLocale: 'en',
  locales: [
    'bn',
    'cs',
    'da',
    'de',
    'el',
    'es',
    'fi',
    'fil',
    'fr',
    'hi',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'nb',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'sv',
    'th',
    'tr',
    'uk',
    'vi',
    'en',
    'zh-hans',
    'zh-hant'
  ] as const
} as const;

export type Locale = (typeof i18n)['locales'][number];

export type LocaleRecord<T> = Record<Locale, T>;
