'use client';

import { createTheme } from '@mui/material/styles';
import { Roboto } from 'next/font/google';

import { LinkBehavior } from 'common/components/MUI/LinkBehaviour';

import { COLOR } from './colors';

const roboto = Roboto({
  display: 'swap',
  subsets: ['latin'],
  weight: ['300', '400', '500', '700']
});

const baseTheme = createTheme();

export const theme = createTheme({
  ...baseTheme,
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: 'none'
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            borderColor: COLOR.PRIMARY_COLOR
          },
          backgroundColor: COLOR.BACKGROUND,
          border: `2px solid ${COLOR.BACKGROUND}`
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    }
  },
  palette: {
    primary: {
      main: COLOR.PRIMARY_COLOR
    }
  },
  typography: {
    body1: {
      fontSize: '1.1rem'
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    fontFamily: roboto.style.fontFamily,
    h1: {
      color: COLOR.BRAND,
      fontSize: '3.25rem',
      fontWeight: 500,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '2.25rem'
      }
    },
    h2: {
      color: COLOR.BRAND,
      fontSize: '3rem',
      fontWeight: 400,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '2rem'
      }
    },
    h3: {
      color: COLOR.BRAND,
      fontSize: '1.5rem',
      fontWeight: 700,
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1.2rem'
      }
    },
    h4: {
      color: COLOR.BRAND,
      fontSize: '1rem',
      fontWeight: 700
    },
    h5: {
      color: COLOR.BRAND,
      fontSize: '1rem',
      fontWeight: 600
    },
    h6: {
      color: COLOR.BRAND,
      fontSize: '1rem',
      fontWeight: 500
    }
  }
});
