import { AnchorHTMLAttributes, forwardRef, ReactNode } from 'react';

import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';

import { buildUrl } from 'common/i18n/buildUrl';
import { i18n, Locale } from 'common/i18n/i18n.config';

export interface LinkProps extends NextLinkProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href: string;
  lang: Locale;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ children, href, lang, ...props }: LinkProps, ref) => {
  const isDefaultLang = lang === i18n.defaultLocale;
  const path = buildUrl(href, lang);

  const langProps = isDefaultLang ? {} : { hreflang: lang, lang };

  return (
    <NextLink href={path} {...props} {...langProps} ref={ref}>
      {children}
    </NextLink>
  );
});

Link.displayName = 'Link';
