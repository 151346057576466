export const COLOR = {
  BACKGROUND: '#F0F7FF',
  BORDER: 'rgba(0, 0, 0, 0.16)',
  BRAND: '#041B49',
  PRIMARY_COLOR: '#2196F3',
  SECONDARY_COLOR: '#9C27B0',
  WHITE: 'white'
};

export const BACKGROUND = `linear-gradient(289deg, ${COLOR.BRAND} 24.66%, #052461 75.37%)`;

export const BORDER = `1px solid ${COLOR.BORDER}`;
